// @flow
import constants, { aggregationSettings } from '../constants/niq-constants'
import { ITEM_TYPE } from '../config'

const { aggregations } = constants

export const aggDefaultParams = {
  [aggregations.DESCRIPTION]: {
    fieldName: 'dictionary_key',
    size: 200,
    sortBy: 'sales',
    sortOrder: 'desc',
    includeFields: [
      'dictionary_key',
      'norm_description',
      'description',
      'brand_id',
      'brand_name',
      'merchant_name',
      'merchant_id',
      'category_id',
      'category_full_path',
      'in_dict_brand',
      'in_dict_category_fp',
      'is_brand_editable',
      'is_category_editable',
      'source_create_time',
      'brand_added_by',
      'category_added_by',
      'sales',
      'nankey',
      ITEM_TYPE,
      'item_code'
    ],
    aggs: {
      sales: {
        fieldName: 'sales'
      }
    }
  },

  [aggregations.DESCRIPTION_WITH_HISTORY]: {
    fieldName: 'dictionary_key',
    size: 100,
    sortBy: 'revenue',
    sortOrder: 'desc',
    includeFields: [
      'dictionary_key',
      'norm_description',
      'brand_id',
      'merchant_id',
      'category_id',
      'description',
      'brand_name',
      'merchant_name',
      'category_full_path',
      'last_description',
      'last_brand_name',
      'last_category_full_path',
      'in_current',
      'in_previous',
      'in_dict_brand',
      'in_dict_category_fp',
      'is_brand_editable',
      'is_category_editable',
      'source_create_time',
      'brand_added_by',
      'category_added_by',
      'sales',
      'nankey',
      ITEM_TYPE,
      'item_code'
    ],

    aggs: {
      revenue: {
        fieldName: 'revenue'
      },
      last_revenue: {
        fieldName: 'last_revenue'
      }
    }
  }
}

export function buildAggregationRequest(aggType, aggParams, from, to) {
  let params = {}
  switch (aggType) {
    // descriptions
    case constants.aggregations.DESCRIPTION:
    case aggregations.DESCRIPTION_WITH_HISTORY:
      params = aggDefaultParams[aggType]
      if (aggParams.selectedSize) params.size = aggParams.selectedSize
      if (params.sortBy) params.sortBy = aggParams.sortBy
      if (aggParams.sortOrder) params.sortOrder = aggParams.sortOrder
      break

    // BCM over time
    case constants.aggregations.TIME:
      params.fieldName = 'order_date'
      params.splitBy = aggParams.selectedFilter
      params.timeInterval = aggParams.selectedInterval
      if (aggParams.selectedField) {
        params.bucketValue = aggParams.selectedField
      }
      break

    // date histogram fields
    case constants.aggregations.REVENUE:
    case constants.aggregations.LAST_REVENUE:
    case constants.aggregations.WEIGHT:
    case constants.aggregations.LAST_WEIGHT:
    case constants.aggregations.FACTOR:
    case constants.aggregations.LAST_FACTOR:
    case constants.aggregations.QUANTITY:
    case constants.aggregations.LAST_QUANTITY:
    case constants.aggregations.PRICE:
    case constants.aggregations.LAST_PRICE:
    case constants.aggregations.UNITS:
    case constants.aggregations.LAST_UNITS:
    case constants.aggregations.USER_ID:
    case constants.aggregations.LAST_USER_ID:
      params.fieldName = 'order_date'
      params.size = aggParams.selectedSize || 10
      if (aggParams.splitBy && aggParams.splitBy !== 'none') {
        params.splitBy = constants.esFields[aggParams.splitBy] || aggParams.splitBy
      }
      params.timeInterval = aggParams.selectedInterval
      params.bucketValue = constants.esFields[aggType] || aggType
      break

    // top BCM valuues
    case constants.aggregations.BRAND:
    case constants.aggregations.CATEGORY:
    case constants.aggregations.NONE:
    case constants.aggregations.MERCHANT:
    case constants.aggregations.DESCRIPTION_FIRST_WORD:
    case constants.aggregations.SLICE_CATEGORY: // NIQ specific fields
    case constants.aggregations.SLICE_BRAND: // NIQ specific fields
    case constants.aggregations.INVALID_CATEGORY: // NIQ specific fields
    case constants.aggregations.INVALID_BRAND: // NIQ specific fields
    case constants.aggregations.OMNISALES_MODULE: // NIQ specific fields
      const fieldName = aggregationSettings[aggType]?.aggregationField || constants.esFields[aggType]

      params.fieldName = fieldName
      params.aggs = {
        [constants.esFields.revenue]: {
          fieldName: constants.esFields.revenue
        }
      }
      if (aggParams.selectedSize) params.size = aggParams.selectedSize
      params.shard_size = 200 // Empirical value. see IN-1250
      if (aggParams.sortBy) params.sortBy = aggParams.sortBy
      if (aggParams.sortOrder) params.sortOrder = aggParams.sortOrder
      break
    case constants.aggregations.CONTEXT_REVENUE:
    case constants.aggregations.CONTEXT_OTHER_REVENUE:
    case constants.aggregations.QUERIED_REVENUE:
    case constants.aggregations.QUERIED_OTHER_REVENUE:
      params.fieldName = constants.esFields.revenue
      break
    case constants.aggregations.CONTEXT_DESCRIPTION_COUNT:
    case constants.aggregations.QUERIED_DESCRIPTION_COUNT:
      params.fieldName = 'dictionary_key'
      params.type = 'cardinality'
      break
    case constants.aggregations.DICTIONARY_COVERAGE:
      break
    case constants.aggregations.QC_MONITORING_INCLUDE:
      params = {
        fieldName: 'order_date',
        size: aggParams.selectedSize || 8,
        timeInterval: 'month',
        bucketValue: 'revenue'
      }
      if (from && to) {
        params.extended_bounds = {
          min: from,
          max: to
        }
      }
      break
    case constants.aggregations.QC_MONITORING_EXCLUDE:
      params = {
        fieldName: 'order_date',
        size: aggParams.selectedSize || 8,
        timeInterval: 'month',
        bucketValue: 'revenue'
      }
      if (from && to) {
        params.extended_bounds = {
          min: from,
          max: to
        }
      }
      break
    default:
      throw new Error(`Unknown aggType ${aggType}`)
  }
  return params
}
