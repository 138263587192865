/* Fields - shortLabel used for tree, label used for search auto complete or search type combo box */
import config from '../config'

export const appName = config.appName

export const boomerBaseURL = config.boomerBaseURL

export const permissions = {
  adminToolsQCToolEdit: 'admin:tools:qctool:edit',
  adminToolsQCToolQueryInteraction: 'admin:tools:qctool:query:interaction',
  adminToolsCustomReportsQueryExecute: 'admin:tools:custom:reports:query:execute',
  sandboxDeliverableConfigEdit: 'sandbox:deliverable:config:edit',
  sandboxDeliveryQcReview: 'sandbox:currentdelivery:qc:review',
  ropsTaxonomyView: 'rops:taxonomy:view',
  ropsTaxonomyEdit: 'rops:taxonomy:edit',
  supraQCToolAdmin: 'supraqctool:admin',
  supraQCToolEdit: 'supraqctool:edit',
  supraQCToolView: 'supraqctool:view',
  NIQqcToolAdmin: 'niq:qctool:admin',
  NIQqcToolEdit: 'niq:qctool:edit',
  NIQqcToolView: 'niq:qctool:view'
}

export const products = {
  customReportsPeriscope: 'custom:reports:periscope',
  adminTools: 'admin:tools:', // For admin tools prefix check only - this is not a real product in database
  adminToolsGroups: 'admin:tools:groups',
  adminToolsUsers: 'admin:tools:users',
  adminToolsQCTool: 'admin:tools:qctool',
  adminToolsSupraQCTool: 'admin:tools:supraqctool',
  adminToolsFeeds: 'admin:tools:feeds',
  adminToolsCustomReportManager: 'admin:tools:custom:reports:manager',
  adminToolsJobMonitoring: 'admin:tools:job:monitoring',
  adminToolsAuthLogsMonitoring: 'admin:tools:authlogs:monitoring',
  adminToolsDeliveryManager: 'admin:tools:delivery:manager',
  NIQqcTool: 'admin:tools:niq:qctool'
}

/**
 * Identifier of indexes/aliases, what we need to take from ES
 * These values are mapped to the real index/alias in the ES in server side
 *
 * IMPORTANT: These values must be synchronized with the server side values
 *
 * @readonly
 * @enum {string}
 */
export const ES_SCORECARD = {
  /**
   * For CME scorecard
   */
  scorecard: 'scorecard',
  /**
   * For CME non-scorecard values
   */
  nonScorecard: 'nonScorecard',

  /**
   * For NIQ data
   */
  NIQ: 'NIQ'
}

/**
 * Key of 'scoreCard' field in the search filters
 * @type {string}
 */
export const ES_SCORECARD_KEY = 'scoreCard'

/**
 * Search filter key 'all'
 * @type {string}
 */
export const SEARCH_KEY_ALL = 'all'

const constants = {
  fields: {
    merchant_name: {
      label: 'Merchant',
      shortLabel: 'M'
    },
    brand_name: {
      label: 'Brand',
      shortLabel: 'B',
      canBeParent: true
    },
    category_full_path: {
      label: 'Category',
      shortLabel: 'C',
      canBeParent: true
    },
    description_first_word: {
      label: 'First Word',
      shortLabel: 'FW'
    },
    description: {
      label: 'Description',
      shortLabel: 'D'
    },
    revenue: {
      label: 'Revenue',
      shortLabel: 'R'
    },
    item_count: {
      label: 'Item Count',
      shortLabel: 'R'
    },
    last_revenue: {
      label: 'Last Revenue',
      shortLabel: 'LR'
    },
    factor: {
      label: 'Amazon Factor',
      shortLabel: 'F'
    },
    user_unique_weight: {
      label: 'User Weight',
      shortLabel: 'UW'
    },
    quantity: {
      label: 'Quantity',
      shortLabel: 'Q'
    },
    price: {
      label: 'Price',
      shortLabel: 'P'
    },
    units: {
      label: 'Units',
      shortLabel: 'U'
    },
    user_id: {
      label: 'User ID [Approx Count]',
      shortLabel: 'ID'
    },
    revenue_diff: {
      label: 'Revenue Diff'
    },
    other: {
      label: 'Other',
      shortLabel: 'O'
    },
    subject: {
      label: 'Subject',
      shortLabel: 'S'
    },
    template: {
      label: 'Template',
      shortLabel: 'TMPL'
    },
    from_addr: {
      label: 'From Address',
      shortLabel: 'FA'
    },
    domain: {
      label: 'Domain',
      shortLabel: 'DM'
    },
    email_type: {
      label: 'Email Type',
      shortLabel: 'ET'
    },
    bbx_supra: {
      label: 'BBX Supra',
      shortLabel: 'BBXS'
    },
    is_cfg: {
      label: 'IS CFG',
      shortLabel: 'CFG'
    },
    source: {
      label: 'Source',
      shortLabel: 'SRC'
    },
    distinct_msg_uuid: {
      label: '# Emails',
      shortLabel: 'NE'
    },
    distinct_mailbox_uuid: {
      label: '# Mailboxes',
      shortLabel: 'NM'
    },
    cnt_msg: {
      label: '# Messages',
      shortLabel: 'NMSG'
    },
    percentage_msg: {
      label: '% Messages',
      shortLabel: 'PM'
    },
    cnt_mailbox: {
      label: '# Mailboxes',
      shortLabel: 'NM2'
    },
    percentage_msg_total_cusum: {
      label: '% Messages Cusum',
      shortLabel: 'PMC'
    }
  },
  esFields: {
    merchant: 'merchant_name',
    brand: 'brand_name',
    last_brand: 'last_brand_name',
    last_category: 'last_category_full_path',
    last_merchant: 'merchant_name',
    category: 'category_full_path',
    revenue: 'revenue',
    last_revenue: 'last_revenue',
    item_count: 'revenue',
    last_item_count: 'last_revenue',
    weight: 'user_unique_weight',
    last_weight: 'last_user_unique_weight',
    factor: 'factor',
    last_factor: 'last_factor',
    last_user_id: 'user_id',
    description_first_word: 'description_first_word',
    quantity: 'quantity',
    price: 'price',
    none: 'none',
    last_none: 'last_none',
    subject: 'subject',
    from_addr: 'from_addr',
    domain: 'domain',
    email_type: 'email_type',
    is_cfg: 'is_cfg',
    source: 'source',
    distinct_msg_uuid: 'distinct_msg_uuid',
    distinct_mailbox_uuid: 'distinct_mailbox_uuid',
    template: 'template',
    cnt_msg: 'cnt_msg',
    percentage_msg: 'percentage_msg',
    cnt_mailbox: 'cnt_mailbox',
    percentage_msg_total_cusum: 'percentage_msg_total_cusum',
    bbx_supra: 'bbx_supra'
  },
  aggregations: {
    TIME: 'time',
    LAST_TIME: 'last_time',
    REVENUE: 'revenue',
    LAST_REVENUE: 'last_revenue',
    WEIGHT: 'user_unique_weight',
    LAST_WEIGHT: 'last_user_unique_weight',
    FACTOR: 'factor',
    LAST_FACTOR: 'last_factor',
    QUANTITY: 'quantity',
    LAST_QUANTITY: 'last_quantity',
    PRICE: 'price',
    LAST_PRICE: 'last_price',
    UNITS: 'units',
    LAST_UNITS: 'last_units',
    USER_ID: 'user_id',
    LAST_USER_ID: 'last_user_id',
    ITEM_COUNT: 'item_count',
    LAST_ITEM_COUNT: 'last_item_count',
    DESCRIPTION: 'description',
    BRAND: 'brand',
    MERCHANT: 'merchant',
    CATEGORY: 'category',
    STATS: 'stats',
    NONE: 'none',
    DESCRIPTION_WITH_HISTORY: 'descriptionWithHistory',
    DESCRIPTION_FIRST_WORD: 'description_first_word',
    CONTEXT_REVENUE: 'context_revenue',
    CONTEXT_OTHER_REVENUE: 'context_other_revenue',
    CONTEXT_DESCRIPTION_COUNT: 'context_description_count',
    QUERIED_DESCRIPTION_COUNT: 'queried_description_count',
    QUERIED_REVENUE: 'queried_revenue',
    QUERIED_OTHER_REVENUE: 'queried_other_revenue',
    DICTIONARY_COVERAGE: 'dictionary_coverage',
    QC_MONITORING_INCLUDE: 'qc_monitoring_include',
    QC_MONITORING_EXCLUDE: 'qc_monitoring_exclude'
  },
  aggregationSuffix: ' (Last)',
  parentElementPrefix: 'Select All: ',
  searchConditions: {
    CONTAINS: 'contains',
    STARTS_WITH: 'startsWith'
  },
  itemTypes: {
    BOTH_ITEM: 'Both',
    ONLY_CURRENT: 'Only Current',
    ONLY_PREVIOUS: 'Only Previous',
    NEW_ITEM: 'New',
    DELETED_ITEM: 'Deleted',
    NON_ELIGIBLE_ITEM: 'No longer Eligible'
  },
  fieldNames: {
    CATEGORY: 'category_full_path',
    BRAND: 'brand_name',
    MERCHANT: 'merchant_name'
  },
  aggGroups: {
    DRILL_DOWN_CHART: 'drillDownChart',
    SPLIT_BY_CHART: 'splitByChart',
    DRILL_DOWN_OPTIONS: 'drillDownOptions',
    DESCRIPTION_WITH_HISTORY: 'descriptionWithHistory'
  },
  timeInterval: {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    QUARTER: 'quarter'
  },
  chartOptions: {
    absolute: {
      color: {
        current: '#00b0bf',
        last: '#ff564a'
      }
    },
    delta: {
      color: 'green'
    }
  },
  dateFormat: {
    qcTool: 'DD-MMM-YYYY',
    refreshData: 'DD MMM, YYYY h:mm A',
    reportDate: 'DD MMM, YYYY h:mm A'
  }
}

export default constants

export const colorPalette = [
  '#31acbe',
  '#ee6b50',
  '#fab34d',
  '#606060',
  '#1e3667',
  '#00bd80',
  '#ff5720',
  '#cb8f43',
  '#5e6855',
  '#7a9da6',
  '#17757f',
  '#6c8939',
  '#df812f',
  '#9c8b79',
  '#68302d',
  '#dbcb78',
  '#8c6b71',
  '#2875aa',
  '#fddb30',
  '#306036'
]

export const searchToolTabs = {
  EDIT_MODE: 'Analysis',
  QC_MODE: 'Quality Check',
  TRENDS: 'Trends',
  METRICS: 'Metrics'
}

export const supraQCToolTabs = {
  DICTIONARY: 'Dictionary',
  SAMPLING_SESSIONS: 'Sampling Sessions',
  SAMPLING_QC: 'Sampling QC'
}

export const AuthlogEvents = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  ONBOARD_TOKEN_CREATED: 'ONBOARD_TOKEN_CREATED',
  ONBOARD_TOKEN_VERIFIED: 'ONBOARD_TOKEN_VERIFIED',
  ONBOARD_TOKEN_FAILURE: 'ONBOARD_TOKEN_FAILURE',
  RESET_PASSWORD_TOKEN_CREATED: 'RESET_PASSWORD_TOKEN_CREATED',
  RESET_PASSWORD_TOKEN_FAILURE: 'RESET_PASSWORD_TOKEN_FAILURE',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED'
}

export const deliveryEvents = {
  CLIENT_CREATED: 'CLIENT_CREATED',
  CLIENT_UPDATED: 'CLIENT_UPDATED',
  CLIENT_ACTIVATED: 'CLIENT_ACTIVATED',
  CLIENT_DEACTIVATED: 'CLIENT_DEACTIVATED',
  CONTRACT_CREATED: 'CONTRACT_CREATED',
  CONTRACT_UPDATED: 'CONTRACT_UPDATED',
  CONTRACT_ACTIVATED: 'CONTRACT_ACTIVATED',
  CONTRACT_DEACTIVATED: 'CONTRACT_DEACTIVATED',
  DELIVERABLE_CREATED: 'DELIVERABLE_CREATED',
  DELIVERABLE_UPDATED: 'DELIVERABLE_UPDATED',
  DELIVERABLE_CATEGORIES_UPDATED: 'DELIVERABLE_CATEGORIES_UPDATED',
  DELIVERABLE_ACTIVATED: 'DELIVERABLE_ACTIVATED',
  DELIVERABLE_DEACTIVATED: 'DELIVERABLE_DEACTIVATED',
  DELIVERY_CREATED: 'DELIVERY_CREATED',
  DELIVERY_COMPLETED: 'DELIVERY_COMPLETED',
  DELIVERY_CANCELLED: 'DELIVERY_CANCELLED',
  DELIVERY_ARCHIVED: 'DELIVERY_ARCHIVED',
  AD_HOC_DELIVERY_CREATED: 'AD_HOC_DELIVERY_CREATED',
  DELIVERY_DEADLINE_UPDATED: 'DELIVERY_DEADLINE_UPDATED',
  CLEANUP_JOB_CREATED: 'CLEANUP_JOB_CREATED'
}

export const DEBOUNCE_ONCHANGE_UI_ACTION_TIMEOUT = 250

export const supraQCToolDefaults = {
  DICTIONARY_TABLE_SIZE_PER_PAGE: 20,
  SAMPLING_SESSIONS_SIZE_PER_PAGE: 20,
  SAMPLING_QC_SIZE_PER_PAGE: 15,
  SAMPLING_QC_START_DATE: '2019-11-01',
  DATE_FORMAT: 'YYYY-MM-DD',
  DEFAULT_SAMPLE_SIZE: 2410,
  VALID_DATA_SOURCES: ['All', 'CFG', 'Non CFG'],
  DEFAULT_DATA_SOURCE: 'All',
  NUMBERS_AFTER_DECIMAL_POINT: 2,
  UNALLOWABLE_TEMPLATE_REGEX: /[^!-~\s]/g
}

export const validEmailTypes = ['ORDER', 'SHIPPING', 'WELCOME', 'PAYMENT', 'RETURNS', 'CANCELLATION', 'NOT_A_RECEIPT']
export const validSampleTypes = ['Domain']
export const validSources = ['conflict', 'supra', 'staging', 'dictionary']

// Statuses of worker's tasks
export const TASK_STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  SUCCESS: 'success',
  CANCELLED: 'cancelled',
  FAILED: 'failed'
}

export const PORTAL_SYSTEM_USER_ID = 0

export const DOWNLOAD_DATETIME_FORMAT = 'MM-DD-YYYY:HH:mm:ss'

export const HEADER_HEIGHT = 60
export const FOOTER_HEIGHT = 30

export const CME_RETRAINER_IFRAME_URL = 'https://mlstreamlit.prod.slicetest.com/CME_Performance_Viewer'

export const sortModes = {
  DESCENDING: 'desc',
  ASCENDING: 'asc'
}

export const REACT_APP_MUI_LICENSE_KEY =
  '312261a9deb94aa0ee2522483f678bfdTz03NjE2NSxFPTE3MjgyNDA3NjUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'

export const TABLE_MUI_ROWS_CURRENT = 10
export const TABLE_MUI_ROWS_OPTION = [10, 20, 50, 100]
