export const ITEM_TYPE = 'item_type'
export const CODING_MODE = 'coding_mode'

export const fields = [
  { name: 'description', label: 'Description' },
  { name: 'omnisales_module', label: 'Omnisales Module' },
  { name: 'category', label: 'Category' },
  { name: 'brand', label: 'Brand' },
  { name: 'nankey', label: 'Nankey' },
  { name: 'brand_owner', label: 'Brand Owner' },
  { name: 'merchant', label: 'Merchant' },
  { name: ITEM_TYPE, label: 'Item Type' },
  { name: 'sales', label: 'Sales' },
  { name: 'slice_category', label: 'Slice Category' },
  { name: 'slice_brand', label: 'Slice Brand' },
  { name: CODING_MODE, label: 'Coding Mode' },
  { name: 'module_confidence', label: 'Module Confidence' },
  { name: 'brand_confidence', label: 'Brand Confidence' },
  { name: 'subcategory_confidence', label: 'SubCategory Confidence' },
  { name: 'segment_confidence', label: 'Segment Confidence' },
  { name: 'price', label: 'Unit Price' },
  { name: 'invalid_category', label: 'Invalid Category' },
  { name: 'invalid_brand', label: 'Invalid Brand' }
]

const descriptionOperators = [
  { name: 'contains', label: 'contains' }, // for root word and associates
  { name: 'notContains', label: 'not contains' },
  { name: 'matchesStem', label: 'contains (stem)' }, // for root word and associates
  { name: 'notMatchesStem', label: 'not contains (stem)' },
  { name: 'exact', label: 'is' },
  { name: 'notMatches', label: 'is not' },
  { name: 'startsWith', label: 'starts with' },
  { name: 'notStartsWith', label: 'not starts with' },
  { name: 'endsWith', label: 'ends with' },
  { name: 'notEndsWith', label: 'not ends with' },
  { name: 'wildcard', label: 'wildcard' },
  { name: 'notWildcard', label: 'not wildcard' }
]

const bcmOperators = [
  { name: 'matches', label: 'is' },
  { name: 'notMatches', label: 'is not' }
]

const booleanOperators = [{ name: 'eq', label: 'is' }]

const numericOperators = [
  { name: 'eq', label: '=' },
  { name: 'neq', label: '!=' },
  { name: 'lt', label: '<' },
  { name: 'gt', label: '>' },
  { name: 'lte', label: '<=' },
  { name: 'gte', label: '>=' }
]

export const combinators = [
  { name: 'and', label: 'ALL OF ' },
  { name: 'or', label: 'ANY OF ' }
]
const fieldOperators = {
  description: descriptionOperators,
  [ITEM_TYPE]: bcmOperators,
  category: bcmOperators,
  merchant: bcmOperators,
  brand: bcmOperators,
  price: numericOperators,
  invalid_category: booleanOperators,
  invalid_brand: booleanOperators,
  omnisales_module: bcmOperators,
  nankey: numericOperators,
  sales: numericOperators,
  slice_category: descriptionOperators,
  slice_brand: descriptionOperators,
  [CODING_MODE]: bcmOperators,
  module_confidence: numericOperators,
  brand_confidence: numericOperators,
  subcategory_confidence: numericOperators,
  segment_confidence: numericOperators,
  brand_owner: bcmOperators
}

export const controlClassnames = {
  queryBuilder: 'search-query-builder portal-query-builder niq-search-query-builder', // Root <div> element
  combinators: 'qb-combinators btn-md', // <select> control for combinators
  removeGroup: 'ab-remove-group btn-link btn-xs', // <button> to remove a RuleGroup
  fields: 'qb-fields btn-xs', // <select> control for fields
  operators: 'qb-operators btn-xs', // <select> control for operators
  value: 'qb-value btn-xs', // <input> for the field value
  removeRule: 'qb-remove-rule btn-link btn-xs', // <button> to remove a Rule
  disableToggle: 'qb-disable-toggle btn-link btn-xs',
  emptyQueryTree: 'qb-empty-query-tree btn-link btn-xs', // <button> to clear query tree
  getQuerySQL: 'qb-get-query-sql btn-link btn-xs', // <button> to get Query SQL
  repeatRule: 'qb-repeat-rule btn-link btn-xs'
}

export const getOperators = field => fieldOperators[field]
